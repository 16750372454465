@tailwind base;
@tailwind components;
@tailwind utilities;

[x-cloak] {
  display: none !important;
}

@layer base {
  body {
    @apply text-stone-800;
  }
  .link-underline {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#a46b4d, #a46b4d);
    background-size: 0 2px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size 0.3s ease-in-out;
    &-black {
      background-image: linear-gradient(transparent, transparent), linear-gradient(#000, #000);
    }

    &:hover {
      background-size: 100% 2px;
    }
  }
}
